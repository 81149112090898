@mixin minw($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
}

@mixin minh($screen) {
    @media (min-height: $screen+'px') {
        @content;
    }
}

@mixin maxw($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
}

@mixin maxh($screen) {
    @media (max-height: $screen+'px') {
        @content;
    }
}