@import 'mixins';

.video-js.vjs-videojs-share_open .vjs-modal-dialog .vjs-modal-dialog-content {
    display:flex;
    align-items:center;
    padding:0;
    background-image:linear-gradient(to bottom, rgba(0,0,0,0.77), rgba(0,0,0,0.75))
}

.video-js.vjs-videojs-share_open .vjs-modal-dialog .vjs-close-button {
    position:absolute;
    right:0;
    top:5px;
    width:30px;
    height:30px;
    color:#fff;
    cursor:pointer;
    opacity:0.9;
    transition:opacity 0.25s ease-out;
    @include minw(320) {
        right:5px;
        top:10px
    }
    @include minw(660) {
        right:20px;
        top:20px
    }
}

.video-js.vjs-videojs-share_open .vjs-modal-dialog .vjs-close-button:before {
    content:'×';
    font-size:20px;
    line-height:15px
}

.video-js.vjs-videojs-share_open .vjs-modal-dialog .vjs-close-button:hover {
    opacity:1
}

.video-js .vjs-share {
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    width:100%;
    height:100%;
    max-height:400px
}

.video-js .vjs-share__top,.video-js .vjs-share__middle,.video-js .vjs-share__bottom {
    display:flex
}

.video-js .vjs-share__top,.video-js .vjs-share__middle {
    flex-direction:column;
    justify-content:space-between
}

.video-js .vjs-share__middle {
    padding:0 25px
}

.video-js .vjs-share__title {
    align-self:center;
    font-size:22px;
    color:#fff;
    @include minh(400) {
        margin-bottom:15px
    }
}

.video-js .vjs-share__subtitle {
    width:100%;
    margin:0 auto 12px;
    font-size:16px;
    color:#fff;
    opacity:0.7;
    @include maxw(992) {
        display:none
    }
}

.video-js .vjs-share__short-link-wrapper {
    position:relative;
    display:block;
    width:100%;
    height:40px;
    margin:0 auto;
    margin-bottom:15px;
    border:0;
    color:rgba(255,255,255,0.65);
    background-color:#363636;
    outline:none;
    overflow:hidden;
    flex-shrink:0;
    @include minh(400) {
        margin-top:15px
    }
}

.video-js .vjs-share__short-link {
    display:block;
    width:100%;
    height:100%;
    padding:0 40px 0 15px;
    border:0;
    color:rgba(255,255,255,0.65);
    background-color:#363636;
    outline:none
}

.video-js .vjs-share__btn {
    position:absolute;
    right:0;
    bottom:0;
    height:40px;
    width:40px;
    display:flex;
    align-items:center;
    padding:0 11px;
    border:0;
    color:#fff;
    background-color:#2e2e2e;
    background-size:18px 19px;
    background-position:center;
    background-repeat:no-repeat;
    cursor:pointer;
    outline:none;
    transition:width 0.3s ease-out, padding 0.3s ease-out
}

.video-js .vjs-share__btn svg {
    flex-shrink:0
}

.video-js .vjs-share__btn span {
    position:relative;
    padding-left:10px;
    opacity:0;
    transition:opacity 0.3s ease-out
}

.video-js .vjs-share__btn:hover {
    justify-content:center;
    width:100%;
    padding:0 40px;
    background-image:none
}

.video-js .vjs-share__btn:hover span {
    opacity:1
}

.video-js .vjs-share__socials {
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-content:flex-start;
    transition:width 0.3s ease-out, height 0.3s ease-out
}

.video-js .vjs-share__social {
    display:flex;
    justify-content:center;
    align-items:center;
    flex-shrink:0;
    width:32px;
    height:32px;
    margin-right:6px;
    margin-bottom:6px;
    cursor:pointer;
    font-size:8px;
    transition:transform 0.3s ease-out, filter 0.2s ease-out;
    border:none;
    outline:none;
    @include minw(660) {
        width:40px;
        height:40px
    }
}

.video-js .vjs-share__social:hover {
    filter:brightness(115%)
}

.video-js .vjs-share__social svg {
    overflow:visible;
    max-height:24px
}

.video-js .vjs-share__social_vk {
    background-color:#5d7294
}

.video-js .vjs-share__social_ok {
    background-color:#ed7c20
}

.video-js .vjs-share__social_mail,.video-js .vjs-share__social_email {
    background-color:#134785
}

.video-js .vjs-share__social_tw {
    background-color:#76aaeb
}

.video-js .vjs-share__social_reddit {
    background-color:#ff4500
}

.video-js .vjs-share__social_fbFeed {
    background-color:#475995
}

.video-js .vjs-share__social_messenger {
    background-color:#0084ff;
    svg {
        height: 24px;
        width: 24px
    }
}

.video-js .vjs-share__social_gp {
    background-color:#d53f35
}

.video-js .vjs-share__social_linkedin {
    background-color:#0077b5
}

.video-js .vjs-share__social_viber {
    background-color:#766db5
}

.video-js .vjs-share__social_telegram {
    background-color:#4bb0e2
}

.video-js .vjs-share__social_whatsapp {
    background-color:#78c870
}

.video-js .vjs-share__bottom {
    justify-content:center
}

.video-js .vjs-share .hidden-sm {
    @include maxw(992) {
        display:none
    }
}