@import 'mixins';

$primary-foreground-color: #fff;
$primary-background-color: #1c1c1c;

.vjs-overlay-top-left {
	top: 0 !important;
	left: 0 !important;
}

.vjs-waiting-ads .vjs-big-play-button {
	display: none !important;
}

.vjs-waiting-ads .vjs-loading-spinner {
	display: block;
	visibility: visible;
}

// Same animation as in the videojs.css
.video-js.vjs-waiting-ads .vjs-loading-spinner:before,
.video-js.vjs-waiting-ads .vjs-loading-spinner:after {
	-webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
		vjs-spinner-fade 1.1s linear infinite;
	animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
		vjs-spinner-fade 1.1s linear infinite;
}

.vjs-overlay-background {
	padding: 5% 5% !important;
	width: 100% !important;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(5, 4, 5, 0.82)),
		to(rgba(5, 4, 5, 0))
	) !important;
	font-family: sans-serif !important;
}

.vjs-overlay {
	height: 30%;
	zoom: 1;
	font-size: 25px;
	color: #fff;
	text-align: left !important;

	@include maxw(899) {
		font-size: 19.5px;
	}
}

// seeking state, don't display poster but video frame
.vjs-seeking.vjs-has-started.vjs-waiting {
	// applied to element that contains the video
	.vjs-tech {
		display: block;
	}
	.vjs-poster {
		display: none;
	}
}

// initial video&ad buffering state, don't display video frame but poster
.vjs-ad-loading.vjs-has-started,
.vjs-ad-loading.vjs-waiting,
.vjs-has-started.vjs-waiting {
	// applied to element that contains the video
	.vjs-tech {
		display: none;
	}
	.vjs-poster {
		display: block;
	}
}

.video-js {
	font-size: 10px !important;
	color: $primary-foreground-color !important;
	width: 100% !important;
	height: 100% !important;
	z-index: 1;
}

.inline-player-container {
	width: 100%;
	height: 100%;
}

.vjs-nexstar-skin .vjs-big-play-button {
	font-size: 60px !important;
	$big-play-width: 1.5em;
	$big-play-height: 1.5em;

	line-height: $big-play-height !important;
	height: $big-play-height !important;
	width: $big-play-width !important;

	border: 0.06666em !important;
	border-radius: 50% !important;

	left: 50% !important;
	top: 50% !important;
	margin-left: -($big-play-width / 2) !important;
	margin-top: -($big-play-height / 2) !important;
}

.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
	background-color: $primary-background-color !important;
}

$slider-bg-color: lighten($primary-background-color, 33%);

.video-js .vjs-slider {
	background-color: $slider-bg-color !important;
	background-color: rgba($slider-bg-color, 0.5) !important;
	background-color: rgba(255, 255, 255, 0.3) !important;
	border-radius: 2px !important;
}

.video-js .vjs-volume-level,
.video-js .vjs-play-progress {
	background-color: #5A86FF !important;
}

.video-js .vjs-progress-holder {
	height: 0.8em !important;
}

.video-js .vjs-load-progress {
	background: rgba($slider-bg-color, 0.5) !important;
}

.video-js .vjs-load-progress div {
	background: rgba($slider-bg-color, 0.75) !important;
}

.vjs-nexstar-skin:hover .vjs-big-play-button {
	background-color: transparent !important;
}

.vjs-nexstar-skin .vjs-progress-holder {
	margin: 0 !important;
}

.vjs-nexstar-skin .vjs-progress-control .vjs-progress-holder:after {
	border-radius: 2px !important;
	display: block !important;
	height: 0.8em !important;
}

.vjs-nexstar-skin .vjs-progress-control .vjs-load-progres,
.vjs-nexstar-skin .vjs-progress-control .vjs-play-progress {
	border-radius: 2px !important;
	height: 0.8em !important;
}

.vjs-nexstar-skin .vjs-playback-rate {
	display: none !important;
}

.vjs-nexstar-skin .vjs-volume-menu-button:before {
	width: 1.2em !important;
	z-index: 1 !important;
}

.vjs-nexstar-skin .vjs-mouse-display:before,
.vjs-nexstar-skin .vjs-play-progress:before,
.vjs-nexstar-skin .vjs-volume-level:before {
	font-size: 17px !important;
}

.vjs-nexstar-skin .vjs-mouse-display:after,
.vjs-nexstar-skin .vjs-play-progress:after,
.vjs-nexstar-skin .vjs-time-tooltip {
	width: 5.5em !important;
}

.vjs-nexstar-skin .vjs-audio-button {
	display: none !important;
}

.vjs-nexstar-skin .vjs-volume-level {
	background-color: #5A86FF !important;
}

.vjs-nexstar-skin.video-js {
	.vjs-volume-panel .vjs-volume-control {
		display: flex !important;
	}

	&.vjs-layout-x-small,
	&.vjs-layout-small {
		.vjs-volume-panel .vjs-volume-control {
			width: 5em !important;
		}
	}
	&.vjs-layout-medium,
	&.vjs-layout-large,
	&.vjs-layout-x-large {
		.vjs-volume-panel .vjs-volume-control {
			width: 10em !important;
		}
	}
}

.vjs-nexstar-skin .vjs-volume-panel .vjs-volume-vertical {
	color: #fff !important;
	left: -3em !important;
	background-color: #373737 !important;
}

.vjs-nexstar-skin .vjs-control {
	flex: unset;
}

/** This normalizes the share icon size and makes sure the fullscreen icon fits in the control bar.
 * Currently this is set up for video bins on desktop and mobile.
 * This applies when they're in the sidebar, daily news, article lead media, and video center.
 * It also applies to their floating players.
*/
.vjs-nexstar-skin {
	.vjs-share-control {
		font-size: 20px;
	}

	.vjs-custom-control-spacer {
		display: none !important; // important needed to overwrite video-js code we can't edit
	}

	&.video-js.vjs-layout-x-small,
	&.video-js.vjs-layout-small,
	&.video-js.vjs-layout-medium {
		.vjs-control {
			width: 3em;

			&.vjs-share-control {
				font-size: 15px;
				width: 1.25em;
			}
		}
	}

	&.video-js.vjs-layout-large,
	&.video-js.vjs-layout-x-large {
		.vjs-control {
			width: 3em;

			&.vjs-share-control {
				width: 1.5em;
				@media (max-width: 1000px) {
					font-size: 17px;
					width: 1.35em;
				}
			}
		}
	}
}

.vjs-nexstar-skin .vjs-control-bar {
	font-size: 14px !important;
	@include maxw(992) {
		font-size: 12px !important;

		.vjs-control.vjs-button {

			.vjs-icon-placeholder:before {
				font-size: 2.3em !important;
				line-height: 1.40 !important;
			}

			&.vjs-icon-share:before{
				font-size: 1.5em !important;
				line-height: 1.40 !important;
			}

		}

		.vjs-progress-control:hover .vjs-progress-holder {
			font-size: 1.1em;
		}
		
		.vjs-play-progress:before  {
			top: -0.2em;
		}
	
	}

	.vjs-play-control {
		order: 0;
		:hover {
			color: #5A86FF !important;
		}
	}

	.vjs-volume-panel {
		order: 1;
		:hover {
			color: #5A86FF !important;
		}
	}

	.vjs-progress-control {
		bottom: 3em !important;
		position: absolute;
		width: 100% !important;
		height: 0.85em !important;
	}

	.vjs-current-time {
		width: 3em !important;
		justify-content: center;
		display: inline-flex !important;
		margin-left: auto !important;
		order: 3;
	}

	.vjs-time-divider {
		justify-content: center;
		display: inline-flex !important;
		order: 4;
		padding-left: 0 !important;
		padding-right: 0.75em !important;
		position: relative;
		min-width: 1em;
	}

	.vjs-duration {
		padding: 0;
		display: inline-flex !important;
		margin-left: -0.5em !important;
		order: 5;
	}

	.vjs-playlist-interface-button {
		background-image: url('video-playlist-icon.svg');
		background-repeat: no-repeat;
		background-position-y: center;
		content: '';
		margin: 0 0.5em;
		order: 6;
		width: 1.5em !important;
	}

	.vjs-subs-caps-button {
		display: inline-flex !important;
		order: 7;
		:hover {
			color: #5A86FF !important;
		}
	}

	.vjs-quality-selector {
		order: 8;
		:hover {
			color: #5A86FF !important;
		}
	}

	.vjs-share-control {
		order: 9;
	}

	.vjs-share-control:hover:before {
		color: #5A86FF !important;
	}

	.vjs-fullscreen-control {
		order: 10;
		:hover {
			color: #5A86FF !important;
		}
	}

	.vjs-playlist-interface-button {
		cursor: pointer;
	}
}
// .vjs-nexstar-skin.vjs-layout-large .vjs-control-bar,
// .vjs-nexstar-skin.vjs-layout-x-large .vjs-control-bar {
// 	.vjs-time-divider {
// 		// padding-left: 1em !important;
// 	}
// }

.vjs-nexstar-skin.vjs-layout-small .vjs-control-bar,
.vjs-nexstar-skin.vjs-layout-x-small .vjs-control-bar {
	.vjs-current-time {
		display: none !important;
	}

	.vjs-time-divider {
		display: none !important;
	}

	.vjs-duration {
		display: none !important;
	}

	.vjs-menu .vjs-menu-content {
		max-height: none !important;
	}
}

.vjs-nexstar-skin.vjs-layout-small:not(.player-with-playlist) .vjs-control-bar,
.vjs-nexstar-skin.vjs-layout-x-small:not(.player-with-playlist) .vjs-control-bar {
	.vjs-subs-caps-button {
		margin-left: auto !important;
	}
}

.vjs-nexstar-skin.vjs-layout-small.player-with-playlist .vjs-control-bar,
.vjs-nexstar-skin.vjs-layout-x-small.player-with-playlist .vjs-control-bar {
	.vjs-playlist-interface-button {
		margin-left: auto !important;
	}
}

.vjs-nexstar-skin .vjs-text-track-display {
	bottom: 5em !important;
}

.vjs-nexstar-skin.vjs-live .vjs-control-bar {
	.vjs-current-time {
		display: none !important;
	}

	.vjs-time-divider {
		display: none !important;
	}

	.vjs-duration {
		display: none !important;
	}

	.vjs-subs-caps-button {
		margin-left: auto !important;
	}

	.vjs-progress-control {
		display: none !important;
	}
}

.vjs-nexstar-skin.vjs-layout-tiny,
.vjs-nexstar-skin.vjs-layout-x-small,
.vjs-nexstar-skin.vjs-layout-small,
.vjs-nexstar-skin.vjs-layout-medium {
	.vjs-modal-dialog.vjs-text-track-settings {
		height: 100% !important;
	}

	.vjs-endscreen {
		font-size: 15px !important;

		.swiper-container {
			width: 80%;
		}
	}
}

// Floating player adjustments
.floating {
	/* Stickyness. */
	position: fixed;
	z-index: 1000;

	/* Background. */
	width: 30vw;
	padding: 0px;
	margin: 0px auto;
	background: black;

	@media only screen and (min-width: 601px) {
		width: 400px;
		height: 225px;
		bottom: 150px;
		right: 20px;
	}

	@media only screen and (max-width: 600px) {
		top: 0px;
		left: 0px;
		width: 100vw;
		margin: 0px;

		&.minimized {
			.vjs-nexstar-skin:not(.video-js.vjs-fullscreen) {
				padding-top: 28vw;
				max-width: 50%;

				.vjs-control-bar {
					background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(5, 4, 5, 0)), to(rgba(5, 4, 5, 0.819608))) !important;

					justify-content: space-around;
				}

				.vjs-control {
					width: 36px;
				}

				.vjs-progress-control {
					display: none;
				}

				.vjs-share-control {
					font-size: 18px;
				}

				.vjs-volume-level {
					height: 0.5em;
				}

				.vjs-subs-caps-button, .vjs-playlist-interface-button, .vjs-menu-button, .vjs-share-control {
					display: none !important;
				}

				.vjs-playlist-interface-button, .vjs-menu-button, .vjs-share-control {
					display: none;
				}

				.vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover {
					width: 6em;
				}
				.vjs-endscreen {
					.replay-current, .swiper-button-next, .swiper-button-prev, .swiper-slide {
						display: none;
					}

					.swiper-container {
						height: 100%;
						width: 100%;
					}

					.swiper-slide-active {
						display: block;
						width: 100% !important;

						.recommendation-title {
							display: none;
						}
					}
				}
			}
			// remove captions on minimized mode
			.vjs-nexstar-skin {
				.vjs-text-track-display {
					display: none !important;
				}
				/* Works on Chrome, Edge, and Safari. Not 100% guaranteed to work in future. */
				video::-webkit-media-text-track-display {
					display: none !important;
				}
			}
      // Add captions on fullscreen minimized mode
			.vjs-fullscreen {
				.vjs-text-track-display {
					display: block !important;
				}
				/* Works on Chrome, Edge, and Safari. Not 100% guaranteed to work in future. */
				video::-webkit-media-text-track-display {
					display: block !important;
				}
			} 
			
			.title-bar {
				display: none;
			}

			#min-right-side {
				z-index: 9001;
				.close-minimized-player {
					color: white;
					height: 19px !important;
					margin: 1.5%;
					position: absolute;
					right: 0;
					top: 0;
					width: 19px !important;
					z-index: 1;
				}
			}

			#touch-area {
				display: flex;
				flex-direction: column;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
 				width: 50%;

				.video-status {
					color: #ababab;
					font-family: 'Source Sans Pro', sans-serif;
					font-size: 13px;
					font-weight: 700;
					line-height: 16px;
					padding: 13px 15px 5px;
				}

				.min-title {
					color: white;
					display: block;
					font-family: 'Source Serif Pro', serif;
					font-size: 16px;
					font-weight: 700;
					padding: 0 15px 5px;
					line-height: 16px;
					padding-bottom: 0.5%; // To allow for letters that go low, like g and p, but mostly not show any of the next line of text.
					position: relative;
				}

				button {
					all: unset;
				}

				.mobile-expand-button {
					align-self:center;
					background-color: #666;
					border-radius: 11px;
					bottom: 5px;
					cursor: pointer;
					padding: 3px 0;
					position: absolute;
					width: 40%;
				}
			}

			.ima-slider-div {
				display: none;
			}

			.ima-controls-div {
				height: 0.3em;
			}

			.ima-controls-div-showing {
				height: 1.9em;
			}

			.ima-fullscreen-div, .ima-mute-div, .ima-play-pause-div, .ima-seek-bar-div {
				line-height: 1.5em;
				top: 0;
			}

			.ima-mute-div {
				right: 5em;
			}
		}

		&.expanded .title-bar {
			.close-expanded-player {
				font-size: 1.8rem;
				margin-right: 0.8rem;
			}
		}
 	}

	.vjs-nexstar-skin {
		.vjs-endscreen {
			.swiper-container {
				top: 5%;
			}
		}
		.vjs-overlay.vjs-overlay-top-left.vjs-overlay-background {
			display: none;
		}
		.playlist-item-title {
			font-size: 1.5em;
		}
	}
}

.title-bar {
	background: #1C1C1C;
	color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border: 1px solid black;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	& * {
		font-weight: bold;
		margin: 1%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.close-expanded-player {
	color: white;
	font-size: 1.7rem;
	margin: 1%;
	right: 0;
}

.vjs-nexstar-skin .vjs-menu li.vjs-selected {
	background-color: #fff !important;
}

.vjs-nexstar-skin .vjs-endscreen {
	background: rgb(0, 0, 0);
	color: white;
	display: none;
	font-family: 'Source Serif Pro';
	font-size: 15px;
	font-weight: 700;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	.replay-current {
		align-items: flex-start;
		display: inline-flex;
		margin-bottom: 15px;
		width: 95%;

		.vjs-play-control {
			top: -15px;
			width: 2.5em;
		}

		.current-video-title {
			display: inline-block;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			padding-bottom: 2px; // This prevents letters like "g" from getting cut off at the bottom.
		}
	}

	.swiper-container {
		overflow: hidden;
		width: 90%;

		@media (max-width: 899px) {
			top: 5%;
		}

		.swiper-slide {
			.recommendation-image {
				aspect-ratio: 1.79 / 1;
				height: 100%;
				width: 100%;
			}

			.highlighted-recommendation-image {
				opacity: 0.5;
			}

			.recommendation-wrapper {
				position: relative;

				.next-vid-countdown {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					white-space: nowrap;
				}
			}

			.recommendation-title {
				max-height: 28px;
				overflow: hidden;
				padding-bottom: 1px; // This prevents letters like "g" from getting cut off at the bottom.
				text-align: start;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	.swiper-button-prev {
		background: transparent no-repeat center url('./chevron-left-white.svg');
	}

	.swiper-button-next {
		background: transparent no-repeat center url('./chevron-right-white.svg');
	}

	.swiper-button-next::after,
	.swiper-button-prev::after {
		content: '';
	}
}

.vjs-endscreen.vjs-ended {
	display: flex;
	justify-content: center;
	align-items: center;
}

.vjs-nexstar-skin .vjs-playlist-interface {
	background: rgba(0, 0, 0, 0.85);
	color: white;
	display: none;
	font-family: 'Source Serif Pro';
	font-weight: 700;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: 65%;
	z-index: 1;

	.playlist-header {
		display: flex;
		font-family: 'Source Sans Pro';
		font-size: 2.5em;
		height: 1.5em;
		justify-content: space-between;
		padding: 3% 1.5% 6% 3%;
		text-transform: uppercase;
		width: 100%;

		.playlist-close {
			cursor: pointer;
		}
	}

	.playlist-wrapper {
		height: 100%;
		overflow: hidden scroll;

		/* Works on Firefox. Not guaranteed to work in future. */
		scrollbar-width: thin;
		scrollbar-color: #ffffff #434343;

		:hover {
			background-color: rgba(130, 130, 130, 1);
		}

		/* Works on Chrome, Edge, and Safari. Not 100% guaranteed to work in future. */
		&::-webkit-scrollbar {
			width: 20px;
		}

		&::-webkit-scrollbar-track {
			background: #434343;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #ffffff;
			border-radius: 10px;
			border: 8px solid #434343;
		}

		.playlist-item {
			cursor: pointer;
			padding: 1% 3%;
			overflow: hidden;
			position: relative;
			width: 100%;

			.playlist-item-text {
				float: right;
				height: 100%;
				padding: 3% 1.5%;
				position: absolute;
				right: 0;
				top: 0;
				width: 48%;

				.playlist-item-title {
					display: -webkit-box;
					font-size: 1.75em;
					line-height: 1.2em;
					overflow: hidden;
					padding-bottom: 0.5%; // To allow for letters that go low, like g and p, but mostly not show any of the next line of text.
					position: relative;
					text-overflow: ellipsis;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					width: 100%;
				}
			}

			.playlist-item-image-wrapper {
				height: 100%;
				width: 50%;

				.playlist-item-image {
					aspect-ratio: 16 / 9;
					background: black;
					border-radius: 3px;
					height: 100%;
					object-fit: contain;
					width: 100%;
				}
			}
		}

		.playlist-item.current {
			background-color: rgba(238, 63, 37, 1);
			:hover {
				background-color: rgba(238, 63, 37, 1);
			}
		}
	}
}

.vjs-nexstar-skin .vjs-playlist-interface.visible {
	display: flex;
	flex-direction: column;
}

.ad-text-overlay {
	font-size: 1.125em;
	height: fit-content;
	padding: 2% 2% !important;
}
.ad-text-overlay-learn_more {
	font-size: 1.125em;
	height: fit-content;
	padding: 2% 2% !important;
	background: transparent !important;
	top: 0 !important;
	right: 0 !important;
	cursor: pointer;
}

// XL (Fullscreen) settings
.vjs-x-large.vjs-nexstar-skin,
.vjs-fullscreen.vjs-layout-x-large.vjs-nexstar-skin {
	.vjs-endscreen {
		.swiper-button-prev,
		.swiper-button-next {
			top: 47%;
		}

		.swiper-button-prev {
			left: 45px;
		}

		.swiper-button-next {
			right: 45px;
		}
	}
	.vjs-playlist-interface {
		.playlist-header {
			font-size: 3em;
			padding: 2% 0% 4% 3%;
		}

		// Extra specificity to allow this to overwrite font size settings for other sizes.
		.playlist-item .playlist-item-text .playlist-item-title {
			font-size: 3em;
		}
	}
}

// Medium settings
.vjs-layout-medium.vjs-nexstar-skin {
	.vjs-endscreen {
		.swiper-button-prev,
		.swiper-button-next {
			top: 55%;
		}
	}
	.vjs-playlist-interface {
		.playlist-header {
			font-size: 2em;
			padding: 3% 1.5% 7% 3%;
		}
	}
}

.video-playlist__player,
.article-header {
	.vjs-layout-medium.vjs-nexstar-skin {
		.vjs-endscreen {
			.swiper-button-prev,
			.swiper-button-next {
				top: 42%;
			}
		}
	}
}

// Small settings
.vjs-layout-small.vjs-nexstar-skin {
	.vjs-endscreen {
		.swiper-button-prev,
		.swiper-button-next {
			top: 38%;
		}
	}
	.playlist-header {
		font-size: 1.75em;
		padding: 4% 3% 5%;
	}
	.vjs-playlist-interface {
		.playlist-wrapper {
			margin-top: 2%;

			.playlist-item .playlist-item-text .playlist-item-title {
				font-size: 1.5em;
			}
		}
	}
}

.vjs-fullscreen.vjs-layout-small.vjs-nexstar-skin {
	.playlist-header {
		font-size: 2em;
		padding: 3% 2% 4% 3%;
	}
}

// X-small settings
.vjs-layout-x-small.vjs-nexstar-skin {
	.vjs-endscreen {
		.swiper-button-prev,
		.swiper-button-next {
			top: 35%;
		}

		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			right: 0;
		}
	}

	.vjs-volume-panel .vjs-volume-control {
		display: none !important; // hide volume slider on smaller devices
	}
}

@media only screen and (max-width: 600px) {

	.vjs-volume-panel.vjs-control .vjs-volume-control.vjs-volume-horizontal {
		display: none !important; // hide volume slider on smaller devices
	}
}

.ima-countdown-div {
	text-align: start;
}

