body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: local('SourceSansPro-Regular'),
		url(./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Source Serif Pro';
	src: local('SourceSerifPro-Regular'),
		url(./assets/fonts/Source_Serif_Pro/SourceSerifPro-Regular.ttf) format('truetype');
}
